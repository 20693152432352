import {
    isEmail, namesFromEmail, ucFirst, getCompanyFromEmail,
} from '../helpers/str';

const SubscribeForm = {

    inputErrorClass: 'c-field__input--error',
    hiddenClass: 'h-hidden',
    submitInProcess: false,

    init() {
        this.form = document.querySelector('#subscribe__form');
        if (!this.form) {
            return;
        }

        this.message = document.querySelector('#subscribe__message');
        this.emailField = this.form.querySelector('#subscribe__form-email');

        this.onFormSubmit = this.onFormSubmit.bind(this);

        this.listen();
    },

    listen() {
        if (!this.form) {
            return;
        }

        const formFields = [
            this.emailField,
        ];

        this.form.addEventListener('submit', this.onFormSubmit);

        formFields.forEach(field => {
            field.addEventListener('change', () => {
                this.onFormInputChange(field);
            });
        });
    },

    onFormSubmit(e) {
        e.preventDefault();

        if (this.submitInProcess) return;

        const isValid = this.validateEmail();
        if (!isValid) {
            return;
        }
        this.submitSubscribe();
    },

    submitSubscribe() {
        const email = this.emailField.value;
        const extension = ucFirst(getCompanyFromEmail(email));
        const [
            firstName,
            lastName,
        ] = namesFromEmail(email);

        this.firstName = ucFirst(firstName);
        this.lastName = ucFirst(lastName);

        const data = {
            email,
            firstName: this.firstName,
            lastName: this.lastName,
            extension,
        };
        const ajaxOpts = {
            url: '/subscribe',
            data,
            method: 'POST',
        };
        this.submitInProcess = true;

        $.ajax(ajaxOpts)
            .done(() => {
                this.form.classList.add(this.hiddenClass);
                this.message.classList.remove(this.hiddenClass);

                this.submitInProcess = false;
            })
            .fail(() => {
                this.submitInProcess = false;
            });
    },

    validateEmail() {
        let error;
        const email = this.emailField.value;
        if (!(email && isEmail(email))) {
            this.emailField.classList.add(this.inputErrorClass);
            error = true;
        }

        return !error;
    },

    onFormInputChange(element) {
        element.classList.remove(this.inputErrorClass);
    },
};

export default SubscribeForm;
