import qs from 'qs';
import Dropzone from 'dropzone';
import SubscribeForm from './forms/subscribe-form';

function submitForm($modal, dropzone) {
    const $form = $modal.find('form');
    const $msgOK = $modal.find('.msgOK').hide();
    const $msgBad = $modal.find('.msgBad').hide();
    const $msgFail = $modal.find('.msgFail').hide();

    const formData = new window.FormData($form[0]);
    if (dropzone && !formData.attachment) {
        const files = dropzone.getAcceptedFiles();
        files.forEach(file => formData.append('attachment', file));
    }

    const ajaxOpts = {
        url: $form[0].action,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
    };

    $.ajax(ajaxOpts)
        .done(() => {
            $form[0].reset();
            $form.hide();
            $msgOK.show();
            if (dropzone) dropzone.removeAllFiles();
        })
        .fail(response => {
            if (response.status === 403) {
                $msgBad.show();
            } else {
                $msgFail.show();
            }
        });
}

function initModal($modal) {
    $modal.find('form').show();
    $modal.find('.msgOK').hide();
    $modal.find('.msgBad').hide();
    $modal.find('.msgFail').hide();
}

const filledInputClass = 'c-field__input--filled';
const errorInputClass = 'c-field__input--error';
const validateField = field => {
    const { value } = field;
    const isRequired = field.classList.contains('required');
    field.classList.remove(errorInputClass);
    if (isRequired && !value) {
        field.classList.add(errorInputClass);
    }
};

const processField = field => {
    const { value } = field;
    if (value) {
        field.classList.add(filledInputClass);
    } else {
        field.classList.remove(filledInputClass);
    }

    validateField(field);
};

function initJobModal() {
    const $modal = $('#modalPostJob');

    const requiredFields = $modal.find('.required');
    const checkButton = () => {
        let isError = false;
        requiredFields.each((index, field) => {
            if (!field.value) {
                isError = true;
            }
        });

        if (isError) {
            $modal.find('.postFormBtn').prop('disabled', true);
        } else {
            $modal.find('.postFormBtn').prop('disabled', false);
        }
    };

    $('#postJobBtn').click(() => {
        $modal.modal({
            backdrop: true,
            keyboard: true,
        });
        initModal($modal);
    });
    $modal.find('.postFormBtn').click(() => {
        submitForm($modal);
    });

    const inputFields = $modal.find('.c-field__input');
    inputFields.change(event => {
        const element = event.target;
        processField(element);

        checkButton();
    });

    inputFields.blur(event => {
        const element = event.target;
        validateField(element);
    });

    inputFields.keyup(() => {
        checkButton();
    });
}

let cvDropzone;
function initCVModal() {
    const $modal = $('#modalCV');
    $('#postCVBtn, #postCVBtnBottom, #postCVBtnHeader').click(() => {
        $modal.modal({
            backdrop: true,
            keyboard: true,
        });
        initModal($modal);
    });
    $modal.find('.postFormBtn').click(() => {
        submitForm($modal, cvDropzone);
    });

    const requiredFields = $modal.find('.required');
    const checkButton = () => {
        let isError = false;
        requiredFields.each((index, field) => {
            if (!field.value) {
                isError = true;
            }
        });

        if (isError) {
            $modal.find('.postFormBtn').prop('disabled', true);
        } else {
            $modal.find('.postFormBtn').prop('disabled', false);
        }
    };

    const inputFields = $modal.find('.c-field__input');
    inputFields.change(event => {
        const element = event.target;
        processField(element);

        checkButton();
    });

    inputFields.blur(event => {
        const element = event.target;
        validateField(element);
    });

    inputFields.keyup(() => {
        checkButton();
    });
}

function initHelpModal() {
    const $modal = $('#modalHelp');
    $('#helpBtn').click(() => {
        $modal.modal({
            backdrop: true,
            keyboard: true,
        });
    });
}

function getURLState() {
    const search = location.search.substring(1);
    return search ? decodeURI(search) : '';
}

function initMenu() {
    $('.sidebar-menu .c-dropdown__menu-item').click(function () {
        const $a = $(this);
        const param = `${$a.data('param')}=${$a.data('id')}`;
        let state = getURLState().split('&');

        const paramIndex = state.findIndex(i => i === param);

        if (paramIndex === -1) {
            state.push(param);
        } else {
            state.splice(paramIndex, 1);
        }

        state = state
            .reduce((acc, item) => {
                if (!acc.includes(item)) {
                    acc.push(item);
                }
                return acc;
            }, [])
            .join('&');

        location.search = state;
    });

    $('.js-sidebar-menu span').click(function () {
        const $a = $(this);
        const param = `${$a.data('param')}=${$a.data('id')}`;
        let state = getURLState().split('&');

        const paramIndex = state.findIndex(i => i === param);

        if (paramIndex === -1) {
            state.push(param);
        } else {
            state.splice(paramIndex, 1);
        }

        state = state
            .reduce((acc, item) => {
                if (!acc.includes(item)) {
                    acc.push(item);
                }
                return acc;
            }, [])
            .join('&');

        location.search = state;
    });

    $('.sidebar-menu .dropdown-filter').click(function click(e) {
        const $div = $(this);
        const filterName = $div.data('filter-name');
        const key = `${filterName}Id`;

        const queryParams = qs.parse(getURLState());
        const activeFilters = queryParams[key];

        if (!activeFilters) {
            e.stopPropagation();
            $('.dropdown-toggle').dropdown('toggle');
            return;
        }

        delete queryParams[key];

        window.location.search = qs.stringify(queryParams, { indices: false });
    });

    $('.sidebar-menu li.dropdown-menu-divider').click(function () {
        const elem = $(this);
        const categoryIds = elem.data('ids').split(',');
        const dict = elem.data('dict');

        let state = getURLState()
            .split('&');

        let isAllIncludes = true;
        categoryIds.forEach(id => {
            if (!state.includes(`${dict}Id=${id}`)) {
                isAllIncludes = false;
            }
        });

        if (isAllIncludes) {
            state = state.filter(i => {
                const [, id] = i.split('=');
                return !categoryIds.includes(id);
            });
        } else {
            categoryIds.forEach(id => {
                const paramId = `${dict}Id=${id}`;
                if (!state.includes(paramId)) {
                    state.push(paramId);
                }
            });
        }
        location.search = state.join('&');
    });
}

function openJobOffer() {
    $('.job-section__content a.c-job-offering').click(() => {
        localStorage.setItem('filterState', getURLState());
        return true;
    });
}

function initGoToJobOffers() {
    const $goBack = $('.c-go-back');

    if (!$goBack.length) {
        return;
    }

    const s = localStorage.getItem('filterState');

    $goBack.find('a').attr('href', `/?${s || ''}`);
    $goBack.show();
}

function initDetailsHeader() {
    $(window).scroll(() => {
        const currentPosition = window.pageYOffset || document.documentElement.scrollTop;
        const headerDetails = $('.js-header-details');
        if (currentPosition > 200) {
            headerDetails.addClass('c-header--details-appeared');
        } else {
            headerDetails.removeClass('c-header--details-appeared');
        }
    });
}

function initFilters() {
    $(window).scroll(() => {
        const currentPosition = window.pageYOffset || document.documentElement.scrollTop;
        const filters = $('.js-filters');
        if (currentPosition > 105) {
            filters.addClass('c-filters--scrolled');
        } else {
            filters.removeClass('c-filters--scrolled');
        }
    });
}

function init() {
    initJobModal();
    initCVModal();
    initHelpModal();
    initMenu();
    openJobOffer();
    initGoToJobOffers();
    initDetailsHeader();
    initFilters();
    SubscribeForm.init();
}

Dropzone.options.formCv = {
    autoProcessQueue: false,
    uploadMultiple: false,
    maxFiles: 1,
    paramName: 'attachment',
    createImageThumbnails: false,
    previewTemplate: '<div><span data-dz-name></span></div>',
    previewsContainer: '.dropzone-previews',

    // The setting up of the dropzone
    init: function initDropzone() {
        cvDropzone = this;

        cvDropzone.on('maxfilesexceeded', file => {
            this.removeAllFiles();
            this.addFile(file);
        });
    },
};

window.onload = init;
