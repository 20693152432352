const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@ "]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const isEmail = str => EMAIL_PATTERN.test(str);

const namesFromEmail = email => ((email.split('@') || [])[0] || '').split('.');

const ucFirst = s => (s || '').charAt(0).toUpperCase() + (s || '').slice(1);

const getDomainFromEmail = email => email.split('@')[1];

const getCompanyFromEmail = (email, skipDomains = []) => {
    const domain = getDomainFromEmail(email);

    if (skipDomains.includes(domain)) {
        return '';
    }

    return email.replace(/.*@(.*)\..*$/, '$1');
};

module.exports = {
    isEmail,
    namesFromEmail,
    ucFirst,
    getCompanyFromEmail,
};
